@import '../../../assets/scss/variables';

.other-info-container {
    border        : $main-border solid $primary-color;
    border-radius : 10px;
    display       : flex;
    flex-direction: column;
    width         : 30%;
    padding       : $sectionInnerPadding;

    .contact-info-container {
        display       : flex;
        flex-direction: column;
        margin-bottom : $contentInnerMargin;


        .contact-info-header {
            display      : flex;
            margin-bottom: $contentInnerMargin;
        }

        .contact-info-body {
            display       : flex;
            flex-direction: column;
            margin-top    : 0px;
        }
    }


    .order-info-container {
        .order-info-header {
            display      : flex;
            margin-bottom: 12px;
        }

        .order-info-body {

            .sub-container {
                margin-bottom: $contentInnerSmallMargin;
            }

            .delivery-notes-container {
                .order-info-subheading {
                    margin-bottom: $contentInnerSmallMargin;

                }

                .delivery-notes {
                    width        : 100%;
                    height       : 100px;
                    border       : 1px solid $primary-color;
                    border-radius: 10px;
                    margin-bottom: 12px;
                }

            }
        }
    }
}

@media (max-width:$mobileSizeLarge) {
    .other-info-container {
        width: 100%;
    }
}