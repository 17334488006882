@import '../../../assets/scss/variables';

$sub-color  : grey;
$main-color : black;

@mixin shrinkLabel {
  top      : -14px;
  font-size: 12px;
  color    : $secondary-color;
  margin-top: 4px;
}

@mixin shrinkLabelForm {
  top      : 5px;
  font-size: 12px;
  color    : $secondary-color;
}

.form-input-container {
  position: relative;
  margin  : 45px 0;

  &.isModalInput {
    margin       : 0px;
    width        : 50%;
    padding-right: 10px;
  }

  .form-input {
    background      : none;
    background-color: white;
    font-size       : 18px;
    padding         : 10px 10px 10px 5px;
    display         : block;
    width           : 100%;
    border          : none;
    border-radius   : 0;
    border-bottom   : 1px solid $primary-color;
    margin          : 25px 0;

    &:focus {
      outline: none;
    }

    &:focus~.form-input-label {
      @include shrinkLabel();
    }

    &:focus~.form-input-label {
      &.isModalInput {
        @include shrinkLabelForm();
      }
    }

    &.error {
      border-bottom: 1px solid $error-color;
      ;
    }

  }

  input[type='password'] {
    letter-spacing: 0.3em;
  }

  .form-input-label {
    color         : $primary-color;
    font-size     : 16px;
    font-weight   : normal;
    position      : absolute;
    pointer-events: none;
    left          : 5px;
    top           : 10px;
    transition    : 300ms ease all;

    &.isModalInput {
      top: 5px;
    }

    &.shrink {
      @include shrinkLabel();
    }

    &.shrink {
      &.isModalInput {
        @include shrinkLabelForm();
      }
    }
  }

  .error-message {
    color       : $error-color;
    font-weight : bold;
    font-size   : 14px;
    position    : absolute;
    top         : 30px;
    padding-left: 4px;

    &.isModalInput {
      top: 55px;
    }

  }
}
