@import '../../assets/scss/variables';


.filter-bar-container {
    height: 80%;

    .filter-bar {
        @include dropdown-styles;
    }
}

@media (min-width: $mobileSizeSmall) and (max-width: $mobileSizeLarge) {
    .filter-bar-container {
        width: 100%;
        margin-right: 4px;

        .filter-bar {
            @include dropdown-styles-medium-mobile;


        }
    }
}

@media (max-width:$mobileSizeSmall) {

    .filter-bar-container {
        width: 100%;

        .filter-bar {
            @include dropdown-styles-small-mobile;
        }
    }

}