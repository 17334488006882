@import './../../assets/scss/variables';

.modal-overlay {
    position        : fixed;
    left            : 0;
    right           : 0;
    top             : 0;
    bottom          : 0;
    background-color: rgba(0, 0, 0, 0.8);

}

.modal-container {
    border-radius   : 8px;
    width           : 600px;
    margin          : auto;
    position        : absolute;
    left            : 50%;
    top             : 50%;
    transform       : translate(-50%, -50%);
    background-color: whitesmoke;
    display         : flex;
    flex-direction  : column;
    text-align      : center;

    h2 {
        margin-top: 0px;
        margin    : auto;
        color     : whitesmoke;
    }

    .modal-header {
        background-color: $primary-color;
        width           : 100%;
        border-radius   : 8px;
        padding         : 10px;
        display         : flex;
        align-items     : center;
        height          : 100%;
        color           : whitesmoke;
        // min-height      : 80px;
    }

    .modal-body {
        width          : 100%;
        min-height     : 300px;
        padding        : 10px;
        display        : flex;
        justify-content: space-around;
        flex-wrap      : wrap;
        flex-basis     : 50%;
    }

    .modal-footer {
        background-color: $primary-color;
        border-radius   : 8px;
        width           : 100%;
        display         : flex;
        align-items     : center;
        color           : whitesmoke;
        padding         : 10px;
        justify-content : space-between;
        min-height      : 60px;

        h3 {
            color     : whitesmoke;
            text-align: center;
            margin    : auto;
        }

    }
}

.auth-modal {
    .modal-body {
        display       : flex;
        flex-direction: column;

        button {
            width : fit-content;
            margin: auto;
        }
    }
}

.modal-body {
    &.modal-body-auth {
        width : 500px;
        margin: auto;
    }

}

.auth-option-conatiner {
    display: flex;
}


.shipping-modal {
    .modal-body {
        height: auto;
    }
}


.back-button-icon {
    cursor          : pointer;
    color           : whitesmoke;
    border-radius   : 50%;
    background-color: white;
    padding         : 4px;
}

.next-button-icon {

    color         : rgb(16, 125, 226);
    border-radius : 50%;
    padding       : 4px;
    pointer-events: none;


    &.active {
        pointer-events  : auto;
        cursor          : pointer;
        background-color: white;
    }
}

.close-button-icon {
    color           : whitesmoke;
    background-color: white;
    width           : 15px;
    cursor          : pointer;
    border-radius   : 50%;
    padding         : 4px;
}


@media only screen and (max-width: 650px) {
    .modal-container {
        max-width: 340px;
    }
}