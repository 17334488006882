.Verify-container {
    display: flex;
    flex-direction: column;
    width: 550px;
    align-items: center;
    margin: auto;

    h2 {
        margin: 10px 0px;
    }

    form {
        width: 500px;

    }
}
