


.customer-info-container {
  min-width: 330px;
  min-height:450px;
  border: 2px solid #334095;
  border-radius: 12px;
  background-color: rgb(239, 232, 232);
  display: grid;
  place-items: center;
  text-align: center;
 height:auto;
 width:auto;
 margin-bottom: 5px;
}

.customer-info-container1{
  width: 125px; 
  height: 125px; 
  margin-bottom: 0px;
  background-color: rgb(239, 232, 232);
}

.customer-info-container2 {
  width: 20px; 
  height: 20px;
  margin-left:70px;
  margin-bottom: 0px;
  background-color: rgb(239, 232, 232);
}
  
.profile{
margin-left: 98px;
}

.more-settings-icon {
width: 20px;
height: 20px;
background-size: cover;
margin-right:10px;
float:right;
text-align:right;
margin-top:5px;
}

.detail {
display: flex;
flex-direction: column;
margin-bottom: 20px;
}

.detail span::after {
content: '\A';
white-space: pre;
}

.detail1 span::after {
content: '\A';
white-space: pre;
}
.displayname span::after {
content: '\A';
white-space: pre;
margin-top:0rem;
}

.more-setting-box {
  height:70px;
  width:330px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  border: 2px solid #334095;
  border-radius: 12px;
  padding: 10px;
  background-color: rgb(239, 232, 232);
}

.more-setting-text {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  text-align: center;
  flex-grow: 1; 
}
