@import '../../assets/scss/variables';

.checkout-container {
    width: 55%;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px auto 0;
  
    .checkout-header {
      width: 100%;
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid darkgrey;
  
      .header-block {
        text-transform: capitalize;
        width: 23%;
  
        &:last-child {
          width: 8%;
        }
      }
    }
  
    .total {
      margin-top: 30px;
      margin-left: auto;
      font-size: 36px;
    }
  }
  


.cart-container {
    width         : 75vw;
    // border: 1px solid $secondary-color;
    margin        : auto;
    display       : flex;
    flex-direction: column;
}

.cart-header-container {
    display        : flex;
    flex-direction : column;
    justify-content: space-around;
    height         : auto;
    align-items    : center;

    h1 {
        color : $primary-button;
        margin: 4px;
        margin-bottom: 0px;
    }

    h3 {
        margin-top: 0px;
       
    }
}

.product-customer-info-container {
    width : 80%;
    margin: auto;

    .cart-products-info-container {
        padding           : 4px;
        display           : flex;
        flex-direction    : column;
        border            : 2px solid $primary-button;
        border-radius     : 20px;
        align-items       : flex-start;
        margin: 4px 0;

        .summary-page-subheading {
            font-size          : 20px;
            align-self: flex-start;
            margin-bottom: 2px;
            text-transform: uppercase;
            font-weight: bolder;
            // display: block;
            // background-color: red;
        }

        .cart-products {
            display: flex;

            .product-image {
                width : 150px;
                height: 150px;
                border-radius: 20px;
            }

           
        }
    }
}

.product-details {
    padding: 10px;
    

    h3 {
        margin-top: 0px;
        margin-bottom: 4px;
        font-size: 16px;
    }
}

.empty-cart-message {
    margin: auto;

    button {
        display: block;
        margin: auto;
    }
}

