@import '../src/assets/scss/variables';

body {
  margin : 0;
  padding: 20px 75px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 310px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
  color     : $primary-color
}

@media (min-width: $mobileSizeSmall) and (max-width: $mobileSizeLarge) {
  body {
    padding: 10px;
  }
}


@media (max-width: $mobileSizeSmall) {
  body {
    padding: 10px;
  }
}