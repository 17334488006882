@import '../../../assets/scss/variables';

.order-details-container {
    border        : $main-border solid $primary-color;
    border-radius : 10px;
    display       : flex;
    flex-direction: column;
    padding-bottom: 0px;
    height        : fit-content;
    width         : 65%;
    padding       : $sectionInnerPadding;

    .order-details-headings-container {
        margin-bottom: $contentInnerMargin;
    }

    .order-image-info-container {
        display: flex;

        .order-image-container {

            img {
                width : 200px;
                height: 200px;
            }
        }

        .order-info-container {
            width: 100%;

            h1 {
                margin-bottom: 12px;
            }


            .price-details-container {
                width     : 100%;
                margin-top: 12px;

                h3 {
                    margin-bottom: 4px;
                }

                .price-details {
                    display        : flex;
                    justify-content: space-between;

                    h4 {
                        margin     : 0px;
                        font-weight: 400;

                    }

                    &.subscription-credits {
                        margin-bottom: 12px;
                    }

                    &.credit {
                        border-top: $main-border solid $primary-color;
                        padding: 4px 0px;
                    }

                    &.total {
                        border-top: $main-border solid $primary-color;

                        h4 {
                            font-weight: bold;
                        }
                    }

                }
            }

        }

    }


}

@media (max-width:$mobileSizeLarge) {
    .order-details-container {
        width: 100%;

        .order-image-info-container {
            display: flex;

            .order-image-container {
                img {
                    width : 150px;
                    height: 150px;
                }

            }
        }

        .price-details {
            &.total {
                margin-bottom: 8px;

            }
        }
    }
}

@media (max-width:$mobileSizeSmall) {
    .order-image-container {
        display: none;
    }


}