$primary-color   : rgba(33, 111, 189, 0.947);
$secondary-color : rgb(63, 157, 212);
$primary-button  : rgba(8, 51, 93, 0.947);
$error-color     :rgba(209, 0, 0, 0.91);

$main-border: 3px;
$sub-border : 1.5px;

h1, h2, h3, h4, h5, h6 {
    padding: 0px;
    margin: 0px;
}


@mixin dropdown-styles {
    width        : 200px;
    height       : 100%;
    border-radius: 12px;
    border       : $sub-border solid $primary-color;
    margin       : 4px 8px;
    font-size    : 16px;
}

@mixin dropdown-styles-medium-mobile {
    width      : 100%;
    margin-left: 0px;
}

@mixin dropdown-styles-small-mobile {
    width      : 100%;
    margin-left: 0px;
}




$primary-button-height: 200px;

$main-border: 3px;
$sub-border : 1.5px;


$tabletSize      : 1080px;
$tabletSizeMedium: 930px;
$mobileSizeLarge : 816px;
$mobileSizeSmall : 450px;


$pageTitleSize        : 32px;
$sectionTitleSize     :28px;
$contentHeadingSize   :26px;
$contentSubHeadingSize:20px;
$bodyTextSize         : 18px;


$pageTitlePadding: 30px;
// $sectionOuterPadding: 50px; //UX value
// $sectionInnerPadding: 30px; //UX value

$sectionOuterPadding: 20px;
$sectionInnerPadding: 10px;

$contentInnerMargin: 8px;
$contentInnerSmallMargin: 6px;