.button-clicked {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.dropdown-btn:hover {
  background-color: #f1f1f1;
}

.dropdown-arrow {
  float: right;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border: 2px solid #334095;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.dropdown-content > div:not(:last-child) {
  margin-bottom: 1rem;
  border-bottom: 1px solid grey;
}

.dropdown-content .delivery-details-heading {
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.dropdown-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 0rem;
}

.delivery-address {
  white-space: nowrap;
  max-width: 250px;
  display: block;
  margin-bottom: 0;
}

.grey-line {
  height: 1px;
  background-color: grey;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.edit-date-dropdown {
  margin-top: 0.5rem;
}

.edit-date-option {
  color: black;
  border: none;
  cursor: pointer;
  width: 100%;
  padding: 5px 10px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.edit-date-container {
  position: relative;
}

.edit-date-option {
  padding: 5px;
}

select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.edit-date-btn {
  height: 70px;
  width: 250px;
  color: blue;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 1rem;
  float: right;
}

.skip-order-text {
  height: 50px;
  width: 150px;
  color: blue;
  float: right;
  text-align: right;
  padding-right: 2px;
  margin-left: 800px;
  text-decoration: underline;
}

.skip-order-div {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  border: 2px solid #334095;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.blue-text {
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 2px solid blue;
  border-left: none;
  border-right: none;
  border-top: none;
  background-color: black;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
}

.right-aligned {
  color: white;
  text-decoration: underline;
  margin: 0;
  display: flex;
  align-items: center;
  margin-right: 70px;
  font-size: 18px;
}

.logo {
  margin-right: 7px;
  margin-top: 5px;
  height: 17px;
  width: 17px;
}

.skip-order-txt {
  margin-bottom: 0;
  color: white;
}

.card-child-components {
  margin-left: 20px;
  margin-right: 20px;
}

.skip-order-btn{
  
  height: 50px;
  width: 150px;
  color: white;
  font-size:15px;;
  width:80px;
  height:45px;
  border:none;

  text-decoration: underline;
  background-color:black

}
