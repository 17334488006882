.auth-option-conatiner {
    background-color: rgb(63, 157, 212);
    min-width       : 100px;
    border-radius   : 4px;
    color           : white;
    font-weight     : bold;
    font-size       : 20px;
    border          : none;
    padding         : 12px;
    margin          : 4px 12px;
    width           : 60%;
    margin          : auto;
    display         : flex;
    flex-direction  : column;
    align-items     : center;
    min-height: 70px;
    cursor: pointer;


    h4 {
        margin   : 0px;
        color: whitesmoke;
    }

    span {
        font-size: 16px;
        font-style: italic;
        color: whitesmoke;
    }
}

.auth-option-conatiner:hover {
    background-color: rgb(9, 30, 127);
}
