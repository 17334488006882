@import '../../assets/scss/variables';

.sort-bar-container {
    height: 80%;

    .sort-bar {
        @include dropdown-styles;
    }
}

select:focus {
    outline: 2px solid $primary-color;
}


@media (min-width: $mobileSizeSmall) and (max-width: $mobileSizeLarge) {
    .sort-bar-container {
        width: 100%;
        margin-right: 4px;

        .sort-bar {
            @include dropdown-styles-medium-mobile;


        }
    }
}

@media (max-width:$mobileSizeSmall) {

    .sort-bar-container {
        width: 100%;

        .sort-bar {
            @include dropdown-styles-small-mobile;


        }
    }

}