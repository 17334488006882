@import '../../../assets/scss/variables';

.credit-bar-container {
    border           : $main-border solid $primary-color;
    border-radius    : 10px;
    text-align       : center;
    display          : flex;
    flex-direction   : column;
    // padding-bottom: 10px;
    padding          : $sectionInnerPadding;
    margin-bottom    : $sectionOuterPadding;


    h1 {
        font-size     : 48px;
        margin-top    : 0px;
        margin-bottom : 0px;
        text-transform: uppercase;

        span {
            font-size: 54px;
        }
    }

    span {
        font-size: 24px;
    }
}

@media (min-width: $mobileSizeSmall) and (max-width: $mobileSizeLarge) {
    .credit-bar-container {
        min-height: 75px;

        h1 {
            font-size: 24px;

            span {
                font-size: 28px;
            }
        }

        span {
            font-size: 16px;
        }
    }
}


@media (max-width:$mobileSizeSmall) {
    .credit-bar-container {
        h1 {
            font-size: 24px;

            span {
                font-size: 28px;
            }
        }

        span {
            font-size: 16px;
        }
    }
}