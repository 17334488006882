@import '../../assets/scss/variables';

.search-bar-container {
    height: 80%;

    .search-bar {
        @include dropdown-styles;
    }

    .search-icon {
        border          : 1px solid $primary-color;
        border-radius   : 50%;
        background-color: whitesmoke;
        height          : 100%;
        display         : flex;
        justify-content : center;
        align-items     : center;
        width           : 50px;
    }
}

input::placeholder {
    color     : $primary-color;
    font-style: italic;
    font-size : 16px;
}

input:focus {
    outline: 2px solid $primary-color;
}

@media (min-width: $mobileSizeSmall) and (max-width: $mobileSizeLarge) {
    .search-bar-container {
        height       : 40px;
        margin-bottom: 8px;


        .search-bar {
            margin-right: 0px;
            margin-left : 0px;
        }

        .search-icon {
            width : 45px;
            height: 45px;
        }
    }
}


@media (max-width:$mobileSizeSmall) {


    .search-bar-container {
        height       : 40px;
        margin-bottom: 8px;
        width        : 100%;


        .search-bar {
            width: 100%;
            margin-right    : 0px;
            margin-left     : 0px;
        }

        .search-icon {
            width : 45px;
            height: 45px;
        }
    }

}