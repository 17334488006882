.forget-password-container {
    display: flex;
    flex-direction: column;
    width: 550px;
    margin: auto;
    align-items: center;
    justify-content: center;

    h2 {
        margin: 10px 0px;
    }
}