.shipping-form-container {
    display: flex;
    flex-direction: column;
    width: 550px;
    // background-color: aqua;
    // border: 1px solid black;
    // border: 1px solid blue;

    // h2 {
    //     margin: 10px 0px;
    // }

    .shipping-form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.shipping-submit-button {
    // border: 4px solid red;
    margin: auto;
}




// .shipping-form-container {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-end;
//     margin: auto;
//     border: 1px solid green;
// }

// .shipping-form-container {
//     padding: 0px 60px;
//     border: 1px solid black;
//     border-radius: 4px;
// }

