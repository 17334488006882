.navigation {
    display        : flex;
    height         : 70px;
    align-items    : center;
    justify-content: space-between;

    .logo {
        width : 100px;
        height: 80px;
    }

    .nav-links-container {
        width          : 50%;
        display        : flex;
        align-items    : center;
        justify-content: flex-end;
        height         : 100%;


        .nav-link {
            padding    : 10px 15px;
            cursor     : pointer;
            font-size  : 24px;
            font-weight: bold;
        }
    }
}


@media screen and (max-width: 450px) {
    .navigation {
        width: 550px;
        .nav-links-container {
            width: auto;
            .nav-link {
                font-size: 1em;
            }
        }
    }
}