@import '../../../assets/scss/variables';

.product-search-filter-sort-main-container {
    display        : flex;
    justify-content: space-between;
    width           : auto; 
    margin-bottom: $sectionOuterPadding;

    .category-name-container {
        display    : flex;
        align-items: center;
        margin-left: 4px;
        font-weight: bold;
        font-size  : 24px;
    }

    .filter-sort-search-container {
        display        : flex;
        justify-content: flex-end;
        height         : 60px;
        align-items    : center;
    }
}

.products-cards-container {
    display         : flex;
    flex-wrap       : wrap;
    justify-content : space-between;
    width           : 100%;

    &.low-qty {
        justify-content: left;
    }
}

.products-empty-message {
    font-size: 24px;
}

@media (min-width: $mobileSizeSmall) and (max-width: $mobileSizeLarge) {

    .product-search-filter-sort-main-container {
        display       : flex;
        flex-direction: column;
        height        : 100%;

        .filter-sort-search-container {
            display: flex;
            width  : 100%;
            height : 100%;
        }
    }

}

@media (max-width:$mobileSizeSmall) {

    .product-search-filter-sort-main-container {
        display       : flex;
        flex-direction: column;
        height        : 100%;

        .filter-sort-search-container {
            display       : flex;
            width         : 100%;
            height        : 100%;
            flex-direction: column;
        }
    }

    .products-cards-container {

        &.low-qty {
            justify-content: space-between;
        }
    }

}