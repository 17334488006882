@import '../../assets/scss/variables';

.cart-summary-container {

    .cart-summary-header {
        display        : flex;
        justify-content: space-between;


        .cart-summary-title {
            font-size     : $pageTitleSize;
            padding-bottom: $pageTitlePadding;
        }

        .cart-summary-button {
            margin: auto 0;

            button {
                height: 50px;
            }
        }
    }

    .cart-summary-details {
        display        : flex;
        justify-content: space-between;
    }

}

@media (max-width:$mobileSizeLarge) {
    .cart-summary-details {
        display       : flex;
        flex-direction: column;
    }

    .cart-summary-details> :first-child {
        order: 2;
    }

    .cart-summary-details> :nth-child(2) {
        order        : 1;
        margin-bottom: 8px;

    }
}

@media (max-width:$mobileSizeSmall) {
    .cart-summary-header {
        display       : flex;
        flex-direction: column;
        margin        : auto;

        .cart-summary-title {
            margin: auto;
            height: 40px;
        }

        .cart-summary-button {
            display        : flex;
            justify-content: center;
        }
    }
}