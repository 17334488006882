
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modal-content {
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    max-width: 800px;
    width: 500px;
    text-align: center;
  }
  
  .confirm-button,
  .close-button {
    height: 50px;
    width: 100px;
    margin-top: 30px;
    margin-right:10px;
  }
  
  .close-button {
    margin-left: 20px;
    
  }
  