.payment-form-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    
    h1 {
        margin: 8px 0px;
    }

    span {
        margin-bottom: 32px;
        font-size: 20px;
    }
}