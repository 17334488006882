@import '../../assets/scss/variables';

.page-title {
    font-size      : $pageTitleSize;
    padding-bottom : $pageTitlePadding;
}



.cart-and-otheritems-container {
    display: flex;

    .credit-items-sort-options-container {
        width: 75%;
    }

    .cart-container {
        margin-top      : 0px;
        width           : 25%;
        margin-left     : 10px;
        height          : 85vh;
        margin-left: $sectionOuterPadding;
    }
}


@media (max-width:$tabletSize) {

    .credit-bar {
        width : 100%;
        margin: 0 8px;
    }

    .items-container {
        width: 100%;
    }

    .build-a-box-container-mobile {
        position: relative;
    }

    .credit-items-sort-options-container-mobile {
        margin        : auto;
        display       : flex;
        flex-direction: column;
        align-items   : center;
        width         : 100%;
    }

    .cart-image-container,
    .cart-image-close-container {
        height          : 80px;
        width           : 80px;
        border          : $main-border solid $primary-color;
        border-radius   : 50px;
        display         : flex;
        justify-content : center;
        align-items     : center;
        position        : fixed;
        bottom          : 40px;
        right           : 40px;
        background-color: whitesmoke;

        .cart-img-logo,
        .cart-image-close-icon {
            height: 50px;
        }
    }

    .cart-container-overlay {
        position        : fixed;
        left            : 0;
        right           : 0;
        top             : 0;
        bottom          : 0;
        background-color: rgba(0, 0, 0, 0.8);

        .cart-container-mobile {
            border-radius   : 10px;
            width           : 350px;
            height          : 70%;
            margin          : auto;
            position        : absolute;
            left            : 50%;
            top             : 50%;
            transform       : translate(-50%, -50%);
            background-color: whitesmoke;
            display         : flex;
            flex-direction  : column;
            text-align      : center;
        }
    }


}

@media (min-width: $mobileSizeSmall) and (max-width: $mobileSizeLarge) {
    .build-a-box-container-mobile {
        max-width: 768px;
    }
}


@media (max-width:$mobileSizeSmall) {
    .build-a-box-container-mobile {
        max-width: 450px;
        margin   : auto;
    }
}