@import '../../../assets/scss/variables';

.forgot-password-submit-container {
    display: flex;
    flex-direction: column;
    width: 550px;
    margin: auto;
    color: $primary-color;

    h2 {
        margin: 10px 0px;
        text-align: center;
    }

    span {
        text-align: center;
    }

    .password-reset-button-container {
        display: flex;
        justify-content: space-around;
    }
}