@import '../../assets/scss/variables';

.datepicker-container {
    height  : 0px;
    display : flex;
    position: relative;
    width   : 600px;

    .custom-datepicker {
        border: 1px solid $primary-color;

        .react-datepicker__header {
            background-color: $primary-color;
        }
        .react-datepicker__triangle {
            display: none;
        }
    }
}







// @media only screen and (max-width: 650px) {

// }