.cart-total-container {
    display: flex;
    flex-direction: column;
    text-align: center;

    h1 {
        font-size: 50px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    h2 {
        text-transform: uppercase;
        padding: 0px;
        margin: 0px;
        
    }

    h4 {
        background-color: hsla(0, 10%, 45%, 0.075);
        margin: 0px;
        font-size: 16px;

    }

    h6 {
        font-size: 16px;
        padding: 0px;
        margin-top: 12px;
        margin-bottom: 0px;
    }
}