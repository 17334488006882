@import '../../assets/scss/variables';

.custom-button-container {
    width           : 200px;
    margin          : 10px auto;
    border          : 1px solid $secondary-color;
    background-color: $secondary-color;
    border-radius   : 4px;
    color           : whitesmoke;
    padding         : 0.2rem;
    text-transform  : uppercase;
    font-weight     : bold;
    cursor          : pointer;
    height          : 50px;
    font-size       : 16px;

    &:hover {
        background-color: white;
        color           : $primary-button;
        border          : 1px solid $primary-button;
    }
}