
.edit-date-btn {
  height:70px;
  width:250px;
  color: blue;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  font-size: 1rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  max-width: 800px;
  width:500px;
  height:250px;
}
/*
.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}*/

.confirm-button {
 height:30px;
 width:80px;
margin-top:25px;
}

.close-button {
height:30px;
width:80px;
margin-top:25px;
margin-left:10px;
 
}











