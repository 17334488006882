@import '../../assets/scss/variables';

.orderconfirmation-container {
    display       : flex;
    flex-direction: column;
    text-align    : center;
    width         : 75%;
    margin        : auto;
    align-items   : center;


    .orderconfirmation-heading {

        margin-top: 16px;

        h1 {
            margin: 0;
        }
    }

    img {
        margin: 12px;
        width : 20%;
        height: 20%;
    }

    .orderconfirmation-footer {
        border-top: $main-border solid $primary-color;
        margin-top: 16px;
        padding   : 16px;
        width     : 100%;
    }

    .orderconfirmation-text {
        h3 {
            font-weight: 400;

            span {
                font-weight: bold;
            }
        }
    }

    .orderconfirmation-buttons {
        display: flex;
        width  : 75%;
        margin : auto;
    }
}