@import '../../../assets/scss/variables';


.cart-dropdown-container {
  display         : flex;
  flex-direction  : column;
  background-color: white;
  border          : $main-border solid $primary-color;
  border-radius   : 10px;
  padding         : 0px 10px;
  height          : 100%;

  .cart-items {
    height        : 100%;
    display       : flex;
    flex-direction: column;
    overflow-y    : auto;
  }

  .cart-checkout-button {
    margin : auto 0;
    display: flex;

    button {
      margin: 10px auto;
      width : 100%;
    }

    &.empty-cart {
      cursor: none;

      button {
        &:hover {
          cursor          : none;
          background-color: $secondary-color;
          color           : whitesmoke;
          border          : 1px solid $secondary-color;
        }
      }
    }
  }
}

.cart-items::-webkit-scrollbar {
  width: 4px;
}

.cart-items::-webkit-scrollbar-track {
  background: whitesmoke;
}

.cart-items::-webkit-scrollbar-thumb {
  background-color: $secondary-color;
}


@media (max-width: $tabletSize) {
  .cart-dropdown-container {
    margin-left: 0px;
    border: 8px solid $primary-color;
  }
}