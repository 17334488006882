.dropdown {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
}

.dropdown.open {
  border-bottom-right-radius: 0;
}

.dropdown-btn {
  width: 100%;
  background-color: #f9f9f9;
  color: black;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: none;
  text-align: left;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
  border: 2px solid blue;
  border-radius: 12px;
}

.loader {
  border: 2px solid #123abc;
  border-top: 2px solid transparent;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-left: 450px;
  margin-top: 200px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



.dropdown {
  margin-bottom: 1rem;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;

  &.open {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }


  .dropdown-btn {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;

    &.open {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}