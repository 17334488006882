@import '../../../assets/scss/variables';


.empty-cart-container {
    border: 1px dotted $primary-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    text-align: center  ;
    width: 100%;
    margin-top: 10px;

    .empty-cart-img {
        height:80px;
    }

    span {
        font-size: 20px;
    }
}