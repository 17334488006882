

.button-clicked{
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.edit-date-dropdown {
  margin-top: 40rem;
}

.edit-date-option {
  color: black;
  border: none;
  cursor: pointer;
  width: 100%;
  padding: 5px 10px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.custom-button {
  margin-right:100px;
  width: 280px;
  height: 70px;
  margin-right: 1rem;
  margin-left:0rem;

  background-color: #f1f1f1;
 
  border-radius:12px;
  padding : 0.5rem 1rem;
  font-size: 1.2rem;
   margin-top: 0rem;
  margin-left: 0rem;
  margin-bottom: 2rem;
  border-radius:12px;

  margin-bottom:1rem;
  margin-top:0rem;
}

.ordersbuttons{
display: flex;
 margin-bottom: 1rem;
 margin-right:75px;
}


.custom-button.active {
  background-color: #f1f1f1;
  color: darkblue;
  border: 2px solid darkblue;
  border-radius:12px;
}
