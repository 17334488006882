@import '../../../assets/scss/variables';

.product-card-container {
  width         : 230px;
  height        : 330px;
  border        : $main-border solid $primary-color;
  border-radius : 10px;
  margin        : 5px;
  padding-bottom: 0px;
  text-align    : center;
  position      : relative;
  display       : flex;
  flex-direction: column;
  padding       : 10px;
  align-items   : center;

  &.isLowQty {
    margin-right: 40px;
  }

  h5,
  span {
    font-size: 16px;
  }
}

.product-card-container img {
  height: 50%;
  width : 90%;
}

.product-title {
  font-weight   : bold;
  font-size     : 8px;
  text-transform: uppercase;
  margin        : 8px;
  line-height   : 1;
}

.product-info {
  display        : flex;
  flex-direction : column;
  justify-content: flex-start;
  position       : absolute;
  bottom         : 0.05rem;
  width          : 90%;
}

.product-price {
  font-weight: bold;
  font-size  : 0.9rem;
  margin     : 0.35rem;
}

.qty-container {
  display        : flex;
  justify-content: space-evenly;
  font-weight    : bold;
  font-size      : 0.8rem;
  align-items    : center;
  margin-bottom  : 0.35rem;
  width          : 50%;
  margin         : auto;
  padding-bottom : 4px;
}

.product-footer {
  margin: 0 10px;
}

.add-to-cart-button-container {
  text-align : center;
  align-items: center;
  width      : 90%;
  margin     : auto;

  .add-to-cart-button {
    padding: 0px;

    button {
      height: 50px;
      width : 100%;
    }

    &.negative-balance {

      button {
        background-color: rgb(133, 14, 14);
        border          : 1px solid rgb(133, 14, 14);
        height          : 50px;

      }
    }
  }

}



.qty,
.qty-modifier {
  display        : flex;
  justify-content: center;
  align-items    : center;
  width          : 50%;
  text-align     : center;
  margin         : auto;
}

.qty-modifier {
  cursor: pointer;
}


@media (max-width:$mobileSizeSmall) {
  .product-card-container {
    // width           : 170px;
    width           : 190px;
    height          : 280px;

    &.isLowQty {
      margin-right: 0px;
    }

    h5,
    span {
      font-size: 12px;
    }
  }

  .product-card-container img {
    height: 45%;
    width : 90%;
  }

}

@media (max-width:516px) {
  .product-card-container {
    max-width: 215px;
    height   : 310px;

    &.isLowQty {
      margin-right: 0px;
    }

    h5,
    span {
      font-size: 12px;
    }
  }

  .product-card-container img {
    height: 45%;
    width : 90%;
  }

}

@media (max-width:400px) {
  .product-card-container {
    max-width: 160px;
    height   : 280px;


    &.isLowQty {
      margin-right: 0px;
    }

    h5,
    span {
      font-size: 12px;
    }
  }

  .product-card-container img {
    height: 45%;
    width : 90%;
  }

}