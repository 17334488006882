@import '../../../assets/scss/variables';


.cart-item-container {
  display      : flex;
  align-items  : center;
  padding      : 15px 0;
  max-height   : 80px;
  border-bottom: 1px solid $primary-color;

  .remove-item {
    border       : 1px solid $primary-color;
    border-radius: 50%;
    margin-right : 8px;
    width        : 5%;
    padding      : 2px;
  }

  .item-details {
    width         : 75%;
    display       : flex;
    flex-direction: column;
    font-size     : 16px;


    .name {
      font-weight: bold;
    }
  }

  .cart-item-qty-container {
    display        : flex;
    justify-content: space-between;
    height         : 100%;
    width          : 20%;


    .cart-item-qty-modifier {
      display         : flex;
      border          : 1px solid $primary-color;
      background-color: $primary-color;
      width           : 30%;
      align-items     : center;
      justify-content : center;
      color           : white;
    }

    .cart-item-qty {
      width          : 40%;
      display        : flex;
      align-items    : center;
      border         : 1px solid $primary-color;
      justify-content: center;

    }
  }
}