@import '../../../assets/scss/variables';

$sub-color  : grey;
$main-color : black;
$error-color: rgba(209, 0, 0, 0.91);

@mixin shrinkLabel {
  top      : 5px;
  font-size: 16px;
  color    : $primary-color;
}

.select-input-container {
  position     : relative;
  width        : 50%;
  padding-right: 10px;

  .select-input {
    background      : none;
    background-color: white;
    color           : $primary-color;
    font-size       : 18px;
    padding         : 10px 10px 10px 5px;
    display         : block;
    width           : 100%;
    border          : none;
    border-radius   : 0;
    border-bottom   : 1px solid $primary-color;
    margin          : 25px 0;

    &:focus {
      outline: none;
    }

    &:focus~.select-input-label {
      @include shrinkLabel();
    }

    &.error {
      border-bottom: 1px solid $error-color;
      ;
    }
  }

  .select-input-label {
    color         : $sub-color;
    font-size     : 16px;
    font-weight   : normal;
    position      : absolute;
    pointer-events: none;
    left          : 5px;
    top           : 10px;
    transition    : 300ms ease all;

    &.shrink {
      @include shrinkLabel();
    }
  }

  .error-message {
    color         : rgba(209, 0, 0, 0.91);
    font-weight   : bold;
    font-size     : 14px;
    position      : absolute;
    top           : 55px;
    padding-left  : 4px;

  }
}


@media only screen and (max-width: 650px) {
  .select-input-container {
    select {
      option {
      }
    }

    .error-message {
      font-size: 0.65em;
    }
  }

}

