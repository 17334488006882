@import './../../../assets/scss/variables';

.modal-card-button {
    button {
      
        background-color: $secondary-color;
        width: 250px;
        border-radius: 4px;
        color: white;
        font-weight: bold;
        font-size: 20px;
        border: none;
        padding: 12px;
        margin: 4px 12px;
        
    }

    button:hover {
        background-color: rgb(9, 30, 127);
    }

    button {
        &.clicked {
            background-color: rgb(35, 19, 175);
        }
    }

   
}